import { useEffect, useMemo, useState } from "react";
import { Reorder, Close } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import "./HeaderCompagny.css";
import Link from "next/link";
import useUser from "@/hooks/useUser";
import { useSelectCompagnyContext } from "@/context/CompagnyContext";
import { getUserId } from "@/utils/authToken";
import Image from "next/image";
import CrayonDrawerForm from "../CrayonDrawerForm/CrayonDrawerForm";

const HeaderCompagny = () => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { isConected } = useUser();
  const { compagny, getSectionUi } = useSelectCompagnyContext();
  const userId = getUserId();
  const [isClient, setIsClient] = useState(false); // New state to check if it's client-side

  useEffect(() => {
    // Update state after component mounts
    setIsClient(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const dataLink = useMemo(() => {
    let links = [
      { nameitem: "Accueil", linkitem: `/${compagny.slug}` },
      { nameitem: "Contact", linkitem: `/${compagny.slug}/contact` },
    ];
    if (isConected) {
      const base = [
        ...links,
        { nameitem: "Profil", linkitem: `/${compagny.slug}/profile` },
      ];
      if (userId === compagny.ownerId) {
        return [
          ...base,
          {
            nameitem: "Organisez un événement",
            linkitem: `/${compagny.slug}/organisez`,
          },
        ];
      }
      return base;
    }
    return [
      ...links,
      { nameitem: "Connexion", linkitem: `/${compagny.slug}/connexion` },
    ];
  }, [isConected, compagny]);

  const textColor = useMemo(() => {
    return getSectionUi("header")?.textColor || "white";
  }, [getSectionUi, compagny]);

  if (!compagny.id) return null;
  return (
    <header
      className={`appHeader-businness ${scrolled ? "scrolled-businness" : ""}`}
      style={{
        background: getSectionUi("header")?.bgColor || compagny.primaryColor,
      }}
    >
      <nav className="navbar-businness">
        <Link href={`/${compagny.slug}`}>
          <div className="logo-container-businness">
            <Image
              src={compagny.logoUrl}
              alt={`${compagny.name} logo`}
              loading="lazy"
              decoding="async"
              width={120} // Largeur standard
              height={0} // Hauteur ajustée automatiquement grâce à `layout`
              layout="intrinsic" // Adapte la hauteur en fonction de la largeur tout en préservant les proportions
              objectFit="contain" // Garantit que le logo n'est pas rogné ou déformé
            />
          </div>
        </Link>
        <div className="navbar-left-businness">
          <div className="navbar-listContainer-businness">
            <ul className="navbar-list-businness">
              <li className="navbar-item-businness">
                <Link href={`/${compagny.slug}`}>
                  <span
                    style={{
                      color: textColor,
                    }}
                  >
                    Accueil
                  </span>
                </Link>
              </li>
              {isConected && userId === compagny.ownerId && (
                <li className="navbar-item-businness">
                  <Link
                    href={`/${compagny.slug}/organisez`}
                    style={{
                      color: textColor,
                    }}
                  >
                    Organisez un événement
                  </Link>
                </li>
              )}
              <li className="navbar-item-businness">
                <Link
                  href={`/${compagny.slug}/contact`}
                  style={{
                    color: textColor,
                  }}
                >
                  Contact
                </Link>
              </li>
              {isClient && isConected ? (
                <li className="navbar-item-businness">
                  <Link
                    href={`/${compagny.slug}/profile`}
                    style={{
                      color: textColor,
                    }}
                  >
                    Profil
                  </Link>
                </li>
              ) : (
                <li
                  className="navbar-item-businness"
                  style={{
                    color: textColor,
                  }}
                >
                  <Link
                    href={`/${compagny.slug}/connexion`}
                    style={{
                      color: textColor,
                    }}
                  >
                    Connexion
                  </Link>
                </li>
              )}
            </ul>
            <CrayonDrawerForm
              section="header"
              iconPosition={{ top: -20, right: -30 }}
            />
          </div>
        </div>
        <div className="navbar-sidebar-businness">
          <div style={{ cursor: "pointer" }} onClick={() => toggleDrawer()}>
            {open ? (
              <Close style={{ color: textColor || "white" }} />
            ) : (
              <Reorder style={{ color: textColor || "white" }} />
            )}
          </div>
          <Drawer open={open} onClose={() => toggleDrawer()} anchor="right">
            <div
              className="navbar-sidebar-drawer-businness"
              style={{
                backgroundColor: getSectionUi("header")?.bgColor || "white",
              }}
            >
              <CrayonDrawerForm
                section="header"
                iconPosition={{ top: -100, right: -30 }}
              />
              {dataLink.map((item, index) => {
                return (
                  <div
                    className="sidebar-drawer-item-businness"
                    key={item.nameitem}
                  >
                    <Link
                      onClick={() => setOpen(false)}
                      href={item.linkitem}
                      style={{
                        color: textColor,
                      }}
                    >
                      {item.nameitem}
                    </Link>
                  </div>
                );
              })}
            </div>
          </Drawer>
        </div>
      </nav>
    </header>
  );
};

export default HeaderCompagny;
