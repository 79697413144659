import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon, Create as CrayonIcon } from "@mui/icons-material";
import Draggable from "react-draggable";
import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "@/context/SnackbarContext";
import { getErrorsAsString } from "@/utils/stringMethods";
import { useSelectCompagnyContext } from "@/context/CompagnyContext";
import { SectionUi } from "@/gql/graphql";
import useUser from "@/hooks/useUser";

const CREATE = graphql(`
  mutation CreateSectionUI($data: SectionCreateInput!) {
    createSectionUI(data: $data) {
      id
      bgColor
      btnColor
      btnTextColor
      textColor
      section
      compganyId
    }
  }
`);

// Interface for the component props
interface CrayonDrawerFormProps {
  section: string;
  title?: string;
  iconPosition?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
}

// Interface for form data
interface FormData {
  section: string;
  textColor: string;
  bgColor: string;
  btnColor: string;
  btnTextColor: string;
}

const CrayonDrawerForm: React.FC<CrayonDrawerFormProps> = ({
  iconPosition = { top: 0, right: 20 },
  title = "Modifier l'en-tête",
  section,
}) => {
  const { userId, isConected } = useUser();
  const { compagny, getSectionUi, updateSectionUi, error } =
    useSelectCompagnyContext();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    section,
    textColor: "black",
    bgColor: compagny.primaryColor || "#FFFFFF",
    btnColor: compagny.primaryColor || "#4CAF50",
    btnTextColor: "black",
  });
  const { showSnackbar } = useSnackbar();
  const [createSection, { loading }] = useMutation(CREATE);

  const toggleDrawer = (
    event: React.KeyboardEvent | React.MouseEvent,
    open: boolean
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const handleInputChange =
    (prop: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [prop]: event.target.value });
    };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!loading) {
      createSection({
        variables: {
          data: {
            ...formData,
            compagnyId: compagny.id,
          },
        },
        onCompleted(data) {
          if (data.createSectionUI) {
            updateSectionUi(section, data.createSectionUI as SectionUi);
            setIsOpen(false);
            showSnackbar(
              "La modification a été effectuée avec succès",
              "success"
            );
          }
        },
        onError(error) {
          setIsOpen(false);
          const message = getErrorsAsString(error);
          showSnackbar(message.toLocaleString(), "error");
        },
      });
    }
  };

  useEffect(() => {
    if (getSectionUi(section)) {
      setFormData((prev) => ({
        ...prev,
        bgColor: getSectionUi(section)?.bgColor || prev.bgColor,
        btnColor: getSectionUi(section)?.btnColor || prev.btnColor,
        btnTextColor: getSectionUi(section)?.btnTextColor || prev.btnTextColor,
        textColor: getSectionUi(section)?.textColor || prev.textColor,
      }));
    }
  }, [getSectionUi]);

  if (
    !isConected ||
    (isConected && userId !== compagny?.ownerId) ||
    !compagny ||
    error
  ) {
    return null;
  }

  return (
    <>
      <Draggable>
        <div title={title}>
          <IconButton
            onClick={(e) => toggleDrawer(e, true)}
            sx={{
              position: "absolute",
              ...iconPosition,
              color: "#FFFFFF", // Icône en blanc
              backgroundColor: "#000000", // Fond noir
              zIndex: 9000,
              transition:
                "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
              borderRadius: "50%",
              "&:hover": {
                transform: "scale(1.1)",
                color: "#FFC107", // Jaune vif lors du survol
                backgroundColor: "#333333",
              },
            }}
          >
            <CrayonIcon fontSize="medium" />
          </IconButton>
        </div>
      </Draggable>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={(e) => setIsOpen(false)}
        sx={{
          zIndex: 9999,
          "& .MuiDrawer-paper": {
            width: 320,
            backgroundColor: "white",
            borderRadius: "20px 0 0 20px",
            boxShadow: "-4px 0 15px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Box
          sx={{
            width: 320,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 3,
          }}
          role="presentation"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontFamily: '"Indie Flower", cursive',
              }}
            >
              {title}
            </Typography>
            <IconButton onClick={(e) => toggleDrawer(e, false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <form onSubmit={handleSubmit}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                backgroundColor: "white",
                borderRadius: 3,
              }}
            >
              <TextField
                fullWidth
                label="Couleur du texte"
                type="color"
                value={formData.textColor}
                onChange={handleInputChange("textColor")}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                fullWidth
                label="Couleur de l'arrière-plan"
                type="color"
                value={formData.bgColor}
                onChange={handleInputChange("bgColor")}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                fullWidth
                label="Couleur du bouton"
                type="color"
                value={formData.btnColor}
                onChange={handleInputChange("btnColor")}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                fullWidth
                label="Couleur du texte du bouton"
                type="color"
                value={formData.btnTextColor}
                onChange={handleInputChange("btnTextColor")}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  marginTop: 2,
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "green",
                    opacity: 0.9,
                  },
                }}
              >
                {loading ? "Traitement..." : "Enregistrer"}
              </Button>
            </Paper>
          </form>
        </Box>
      </Drawer>
    </>
  );
};

export default CrayonDrawerForm;
